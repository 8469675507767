<template>
    <div id="competition_list">
        <van-image fit="cover" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210615142739675.png"
                   class="back_cover"/>
        <div class="time_box">
            <div class="time_tips">距离活动结束还有</div>
            <van-count-down :time="time">
                <template #default="timeData">
                    <span class="block">{{ timeData.days }}天</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                </template>
            </van-count-down>
        </div>

        <div class="tab">
            <div @click="changeTab(0)" :class="tabIndex == 0 ? 'tab_focus tab_left' : 'tab_left'">金星榜</div>
            <div @click="changeTab(1)" :class="tabIndex == 1 ? 'tab_focus tab_right' : 'tab_right'">银星榜</div>
        </div>

        <div class="list">
            <div v-for="item in list" class="list_item">
                <div class="title_box">
                    <van-image fit="cover" class="title_image"
                               src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210617173658378.png"/>
                    <div class="title_words">第{{item.chineseNum}}档</div>
                </div>
                <div class="list_content">
                    <div v-for="item2 in item.list" class="person_item">
                        <div class="person_item_left">
                            <div style="margin-right: 10px">
                                <div class="num_icon">No.</div>
                                <div class="num">{{item2.listId}}</div>
                            </div>
                            <img class="avatar"
                                 :src="item2.headImg ? item2.headImg : 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210625104311334.png'" alt="">
                            <div class="person_info">
                                <div class="person_name" style="color: #666666">{{item2.name}}</div>
                                <div class="person_num" style="color: #999999">{{item2.authCode}}</div>
                            </div>
                        </div>
                        <div class="person_item_right">
                            <div style="color: #999999;margin-bottom: 3px">订单金额</div>
                            <div style="color: #f9b022;font-size: 18px">¥{{item2.perf}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {toChineseNum} from "../../../utils/handle";
    import {replaceAppToken} from "../../../utils/appUtil";

    export default {
        name: "list",
        data() {
            return {
                time: 30 * 60 * 60 * 1000,
                timeData: {},
                tabIndex: 0,
                list: [],
                isInApp: !!this.$route.query.token,
            }
        },
        created() {
            document.title = '百万英雄争霸赛'
            if (this.isInApp) {
                replaceAppToken(this.$route.query.token, () => {
                    this.Init()
                });
            } else {
                this.Init()
            }
        },
        methods: {
            Init() {
                this.$toast.loading()
                this.post('/OnlyOnce/HeroCompetitionActivty/GetPerformanceListByStar', {
                    star: this.tabIndex + 1,
                    batch: 2
                }).then(data => {
                    this.$toast.clear()
                    if (data.code) {
                        const endTime = data.response.endtime
                        this.time = new Date(endTime).getTime() - new Date().getTime()
                        //---------------------------------------------------
                        const list = data.response.list
                        list.forEach(item => {
                            item['chineseNum'] = toChineseNum(item.listId)
                        })
                        this.list = list
                        // console.log(list)
                    } else {
                        //身份不匹配
                        return this.$dialog.alert({
                            title: '提示',
                            message: data.msg
                        })
                            .then(() => {
                                this.$router.push('/')
                            })
                    }
                })
            },
            changeTab(e) {
                this.tabIndex = e
                this.Init()
            }
        }
    }
</script>

<style lang="scss">
    #competition_list {
        .colon {
            display: inline-block;
            margin: 0 4px;
            color: #fff;
        }

        .block {
            display: inline-block;
            min-width: 26px;
            box-sizing: border-box;
            padding-left: 4px;
            padding-right: 4px;
            /*margin-right: 5px;*/
            color: #fff;
            font-size: 12px;
            text-align: center;
            background-color: #221c4c;
            box-shadow: 0 0 10px #494e87;
            border-radius: 8px;
        }

        .back_cover {
            position: fixed;
            z-index: -1;
            top: -41px;
            left: 0;
            width: 100%;
        }

        .time_box {
            position: fixed;
            z-index: 0;
            top: 5px;
            right: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .time_tips {
            color: #fff;
            font-size: 14px;
            margin-bottom: 2px;
        }

        .tab {
            margin-top: 200px;
            height: 35px;
            width: 210px;
            border-radius: 500px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            overflow: hidden;
        }

        .tab div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            box-sizing: border-box;
            border: 2px solid #fff;
            color: #fff;
            font-size: 16px;
        }

        .tab_focus {
            background-color: #fff;
            color: #ed3017 !important;
            font-weight: bold;
        }

        .tab_left {
            border-radius: 500px 0 0 500px;
        }

        .tab_right {
            border-radius: 0 500px 500px 0;
        }

        .list {
            position: fixed;
            top: 240px;
            height: calc(100% - 240px);
            width: 100%;
            overflow: scroll;
        }

        .list_item {
            margin-top: 10px;
            width: 90%;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            .list_content {
                background: #fff;
                min-height: 100px;
                border-radius: 12px;
                position: relative;
                top: -22px;
                z-index: -1;
                box-sizing: border-box;
                padding-top: 20px;

                .person_item {
                    margin-top: 10px;
                    padding-bottom: 5px;
                    display: flex;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding-right: 10px;
                    border-bottom: 1px solid #f1f1f1;
                    margin-left: 10px;
                    margin-right: 10px;

                    .person_item_left {
                        display: flex;
                        align-items: center;

                        .avatar {
                            margin-right: 10px;
                            width: 38px;
                            height: 38px;
                            border-radius: 50%;
                        }

                        .num_icon {
                            background: #f9b022;
                            color: #fff;
                            border-radius: 500px;
                            font-size: 8px;
                            padding: 1px 6px;
                        }

                        .num {
                            margin-top: 2px;
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .title_image {
            height: 34px;
            width: 250px;
        }

        .title_box {
            position: relative;
        }

        .title_words {
            color: #fff;
            position: absolute;
            top: 43%;
            left: 52%;
            transform: translateX(-50%) translateY(-50%);
            font-size: 16px;
            font-weight: bold;
        }

    }
</style>